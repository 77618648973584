import React from "react"

import { withReturnsCard } from "./withReturnsCard"
import { Wrapper, Title } from "./ReturnsCardStyles"
import { RichText } from "../../../RichText/RichText"

export const ReturnsCard = withReturnsCard(({ title, content }) => (
  <Wrapper>
    <Title>{title}</Title>
    <RichText>{content}</RichText>
  </Wrapper>
))
